////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(171, 171, 173);
}
.bg-secondaryColor {
  background-color: rgb(182, 197, 6);
}
.bg-thirdColor {
  background-color: rgb(0, 0, 0);
}
.bg-fourthColor {
  background-color: rgb(0, 0, 0);
}
.bg-fifthColor {
  background-color: rgb(0, 0, 0);
}
.bg-sixthColor {
  background-color: rgb(76, 76, 78);
}
.bg-seventhColor {
  background-color: rgb(76, 76, 78);
}
